import React, { Suspense } from "react"
import ReactDOM from "react-dom"

import ApolloClient from "apollo-boost"
import { createBrowserHistory } from "history"
import { ApolloProvider } from "react-apollo"
import { Provider as ReduxProvider } from "react-redux"
import { Route, Router, Switch } from "react-router-dom"

import App from "./js/App"
import CookieConsent from "./js/Components/CookieConsent"
import Footer from "./js/Components/Footer"
import Unsubscribe from "./js/Pages/Static/Unsubscribe"
import Navbar from "./js/Components/Navbar"
import Contact from "./js/Pages/Contact"
import EventDetail from "./js/Pages/Event/EventDetail"
import EventList from "./js/Pages/Event/EventList"
import Homepage from "./js/Pages/Homepage"
import RestaurantDetail from "./js/Pages/Restaurant/RestaurantDetail"
import RestaurantList from "./js/Pages/Restaurant/RestaurantList"
import NotFound from "./js/Pages/Static/NotFound"
import PersonalInformations from "./js/Pages/Static/PersonalInformations"
import PrivacyPolicy from "./js/Pages/Static/PrivacyPolicy"
import TermsAndConditions from "./js/Pages/Static/TermsAndConditions"
import * as serviceWorker from "./serviceWorker"
import configureStore from "./store"

const { store } = configureStore()

const history = createBrowserHistory()

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BE_ENDPOINT}/graphql`,
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_GRAPHQL_TOKEN,
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <ApolloProvider client={client}>
          <Router history={history}>
            <Navbar />
            <main className={"main-content"} role={"main"}>
              <App>
                <Switch>
                  <Route path={"/"} exact={true} component={Homepage} />
                  <Route path={"/doveze/:address"} component={RestaurantList} />
                  <Route path={"/restaurant/:id"} component={RestaurantDetail} />
                  <Route path={"/akce/:urlname"} component={EventDetail} />
                  <Route path={"/akce"} component={EventList} />
                  <Route path={"/obchodni-podminky"} component={TermsAndConditions} />
                  <Route
                    path={"/zpracovani-osobnich-udaju-ve-sprave-partneru"}
                    component={PersonalInformations}
                  />
                  <Route path={"/zasady-ochrany-osobnich-udaju"} component={PrivacyPolicy} />
                  <Route path={"/kontakt"} component={Contact} />
                  <Route path={"/unsubscribe"} component={Unsubscribe} />
                  <Route component={NotFound} />
                </Switch>
              </App>
            </main>
            <Footer />
            <CookieConsent />
          </Router>
        </ApolloProvider>
      </Suspense>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("app")
)

serviceWorker.unregister()
